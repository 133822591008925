<template>
    <div :class="loginType == 3 ? '' : 'goodsOnSale-list'">
        <app-list :opt="opt" @get="onGet" ref="appList"></app-list>
    </div>
</template>
<script>
    import cabinetUtil from "@utils/cabinetUtil.js"
    let validCode = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //支持输入大小写字母，数字，限制1~16位字符
            let reg = /^[A-z0-9]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品编号格式不对"));
            } else {
                callback();
            }
        }
    };
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    export default {
        data() {
            let _this = this;
            return {
                opt: {
                    search: [{
                        key: "merchantId",
                        label: "商家名称",
                        type: 'remoteSearchById',
                        opt: {
                            list: [],
                            change(data) {
                                _this.getStoreList(data)
                            }
                        }
                    }, {
                        key: "itemName",
                        label: "商品名称",
                        maxlength: 32,
                        rules: [{
                            validator: validItemsName,
                            trigger: ['blur']
                        }]
                    }, {
                        key: "itemCode",
                        label: "商品编号",
                        rules: [{
                            validator: validCode,
                            trigger: ['blur']
                        }]
                    }, {
                        key: "category",
                        label: "商品品类",
                    }, {
                        key: "groupId",
                        label: "门店名称",
                        type: "remoteSearchById",
                        opt: {
                            list: []
                        }
                    }, {
                        key: "date",
                        label: "上架时间",
                        type: "data-range"
                    }],
                    columns: [{
                            label: "商品名称",
                            key: "itemName"
                        },
                        {
                            label: "商品编号",
                            key: "itemCode"
                        },
                        {
                            label: "商品图片",
                            key: "itemPic",
                            type: "img-shop"
                        },
                        {
                            label: "商品品类",
                            key: "itemCategory"
                        },
                        {
                            label: "商品售价",
                            key: "sellPrice"
                        },
                        {
                            label: "商家名称",
                            key: "merchantName"
                        },
                        {
                            label: "门店信息",
                            key: "groupName"
                        },
                        {
                            label: "货柜信息",
                            key: "groupInfo"
                        },
                        {
                            label: "上架时间",
                            key: "createdTime"
                        },
                    ],
                    exportButtons: [{
                        type: "Export",
                        url: this.EXport + "/mall-service/v1/counter-info/export-on-sale-list",
                        listName: '在售列表'
                    }],
                },
                merchantFlag: false,
                loginType: 1//1--平台 2--商家 3--供应商
            };
        },
        created() {
            console.log("goodsOnSale-list created!!");

        },
        activated() {
            console.log("goodsOnSale-list activated!!");
            this.merchantFlag = this.getLoginType('loginType');
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                })
            }
            this.loginType = this.cache.getLS('loginType');
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    ...opt.searchForm,
                    startTime: opt.searchForm['date'] ? opt.searchForm['date'][0] : null,
                    endTime: opt.searchForm['date'] ? opt.searchForm['date'][1] : null,
                };
                if (dto.date) {
                    delete dto.date
                }
                this.post("mall-service/doorItems/selectOnSaleItem", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    if (res.data.data && res.data.data.length > 0) {
                        res.data.data.forEach(item => {
                            if (item.doorCode < 10) {
                                item.doorCode = '0' + item.doorCode;
                            }
                            item.groupInfo = item.region ? item.region + item.regionNumber + '-' + item
                                .doorCode : item.doorCode;
                            item.merchantName = item.merchantName
                        })
                    }
                    opt.cb(res.data);
                });
                if(opt.searchForm.merchantId) {
                    this.getStoreList(opt.searchForm.merchantId);
                }else{
                    this.getStoreList();
                }
            },
            // 获取商品品类
            // getCategory() {
            //     let dto = {
            //         pageNum: 1,
            //         pageSize: 9999
            //     };
            //     this.post("mall-service/category/page/query", dto, {
            //         isUseResponse: true
            //     }).then(res => {
            //         var array = res.data.list;
            //         array.forEach(item => {
            //             item.value = item.id;
            //             item.label = item.name;
            //         })
            //         this.opt.search.forEach(item => {
            //             if (item.key == "category") {
            //                 item.opt.list = array;
            //             }
            //         })

            //     })
            // },
            //获取门店列表
            getStoreList(data) {
                let dto = {
                    currentPage: 1,
                    size: 99999,
                };
                if(data) {
                    dto.merchantId = data
                }
                this.post("/mall-service/v1/counterStores/getAllList", dto, {
                    isUseResponse: true,
                }).then(res => {
                    let storeList = res.data.data;
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "label"));
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupId/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'groupId') {
                            item.opt.list = storeList;
                        }
                    })
                });
            },
        }
    };
</script>
<style lang="scss" scope>
.goodsOnSale-list {
    height: calc(100% - 130px);
}
</style>